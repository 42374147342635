<template>
  <div v-if="store.mmData && !store.mmData.user.display_tc && renderFlag" class="row">
    <div class="col-md-12">
      <h4 class="title">Segment Insights Report</h4>
    </div>
    <div class="col-md-12 card">
      <div class="card-header">
        <div class="category">
          utilities table
          <button id="exportMM" class="btn btn-badge btn-round btn-warning pull-right" @click.prevent="() => store.exportMundial(searchQuery)" v-if="store.exportRows.length && store.mmCustomCSVHeaders">
            export csv <i class="nc-icon nc-cloud-download-93"></i>
          </button>          
        </div>
      </div>
      <div class="card-body row body-mm">
        <div class="col-sm-12 mt-2">
          <el-table class="table-striped"
                    :data="queriedData"
                    border
                    style="width: 100%">


            <el-table-column v-for="column in tableColumns"
                             sortable
                             :align="column.align"
                             :key="column.label"
                             :min-width="store.colWidthDict[column.prop]"
                             :prop="column.prop"
                             :label="column.label">
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import { store } from 'src/store'
  import { segmentInsightsCols, segmentInsightsSearch } from './MundialColumns'
  import users from './users'

  Vue.use(Table)
  Vue.use(TableColumn)
  Vue.use(Select)
  Vue.use(Option)

  export default{
    props: ['renderFlag','segmentInsightsList','ogSegmentInsightsList','resetFilters'],
    components: {
      PPagination
    },
    methods: {
      removeIdsFromArray(arr1, arr2) {
        // Create a Set of IDs from the second array for faster lookup
        const idsSet = new Set(arr2.map(obj => obj.id));

        // Filter the first array to remove objects with IDs found in the second array
        const filteredArr = arr1.filter(obj => !idsSet.has(obj.id));

        return filteredArr;
      },
      handleMoneyChange(row, prop) { 
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(tmpVal) : '';

        console.log({newVal})

        row[prop] = newVal == 'NaN' ? '' : newVal
      },
      handleMoneyBlur(row, prop) { 
        const tmpVal = row[prop].replace('$','').replaceAll(',',''),
        newVal = !isNaN(parseInt(tmpVal)) ? new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(tmpVal) : '';

        console.log({tmpVal,newVal})

        row[prop] = newVal == 'NaN' ? '' : newVal
      },
      handleChange(row, prop) { 
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? Number(tmpVal).toLocaleString("en-US") : '';

        console.log({newVal})

        row[prop] = newVal == 'NaN' ? '' : newVal
      },
      handleBlur(row, prop) { 
        const tmpVal = row[prop].replaceAll(',',''),
        newVal = !isNaN(parseInt(tmpVal)) ? Number(tmpVal).toLocaleString("en-US") : '';

        console.log({tmpVal,newVal})

        row[prop] = newVal == 'NaN' ? '' : newVal
      },
      sanitizeMapping (rows) {
        const rowLen = rows.length, cleanRows = JSON.parse(JSON.stringify(store.mmData.segment_insights_list));
        let mapArr = [];

        for (let i = 0; i < rowLen; i++) {
          const tmpRow = rows[i],
          cleanRow = cleanRows.find(costmap => costmap.id === tmpRow.id);
          let mapBool = false;

          // CPM
          if (tmpRow.cpm_cost) mapBool = true;

          // Direct & Indirect Share
          if (tmpRow.rs_direct_cost && tmpRow.rs_cost) mapBool = true;

          // MAP
          console.log({tmpRow, mapBool})

          if (mapBool) {
            const tmpDateTime = (store.mmDate()).toISOString().split('T'),
            tmpDate = tmpDateTime[0],
            tmpTime = tmpDateTime[1].split('.')[0],
            cleanDateTime = [tmpDate, tmpTime].join(' ');

            tmpRow.udatetime = cleanDateTime 
            tmpRow.cdatetime = cleanDateTime 
            tmpRow.mg = tmpRow.mg ? tmpRow.mg : -1 
            tmpRow.mg_daily_imp = tmpRow.mg_daily_imp ? tmpRow.mg_daily_imp.replace(/[^0-9.]/g, "") : -1 
            tmpRow.mg_monthly_imp = tmpRow.mg_monthly_imp ? tmpRow.mg_monthly_imp.replace(/[^0-9.]/g, "") : -1 
            tmpRow.mc = tmpRow.mc ? tmpRow.mc : -1 
            tmpRow.mc_daily_adreq = tmpRow.mc_daily_adreq ? tmpRow.mc_daily_adreq.replace(/[^0-9.]/g, "") : -1 
            tmpRow.mc_monthly_adreq = tmpRow.mc_monthly_adreq ? tmpRow.mc_monthly_adreq.replace(/[^0-9.]/g, "") : -1 
            tmpRow.cpm_cost = tmpRow.cpm_cost ? tmpRow.cpm_cost.replace(/[^0-9.]/g, "") : -1 
            tmpRow.rs_cost = tmpRow.rs_cost ? tmpRow.rs_cost : -1 
            tmpRow.rs_direct_cost = tmpRow.rs_direct_cost ? tmpRow.rs_direct_cost : -1 
            tmpRow.sn_id = cleanRow.sn_id
            tmpRow.device_type_id = cleanRow.device_type_id 
            tmpRow.product_subtype_id = cleanRow.product_subtype_id
            tmpRow.inter = cleanRow.inter 

            mapArr.push(tmpRow)
          }
        }

        return mapArr
      },
      async createMap () {
        console.log({store,queriedData:this.queriedData})

        const tableData = JSON.parse(JSON.stringify(this.queriedData)),
        dateRangeFlag = store.mmData.daterange_flag;
        let mapData = await this.sanitizeMapping(tableData),
        removeData = store.mmData.remove_sncostmap_list,
        minDate = mapData.map(item => new Date(item.date)).reduce((a, b) => a > b ? b : a),
        resetDate = minDate.toISOString().split('T')[0];

        if (dateRangeFlag) {
          const ogRows = this.ogSegmentInsightsList, 
          maxDate = mapData.map(item => new Date(item.date)).reduce((a, b) => a > b ? a : b),
          maxDateArr = ogRows.filter(item => new Date(item.date).getTime() === maxDate.getTime()),
          cleanArr = await this.sanitizeMapping(maxDateArr),
          adjDateArr = cleanArr.map(item => {
            const newDate = new Date(item.date);
            newDate.setDate(newDate.getDate() + 1);  // Increment the date by one day
            return { ...item, date: newDate.toISOString().split('T')[0] };  // Format the date back to 'YYYY-MM-DD'
          });

          mapData = mapData.concat(adjDateArr)
        }
        
        // Confirm removeData =/= mapData
        console.log({mapData, removeData})
 
        if (mapData.length && removeData.length) removeData = this.removeIdsFromArray(removeData, mapData)

        console.log({dateRangeFlag, mapData, removeData, resetDate:`${resetDate.split('-')[0]}-${resetDate.split('-')[1]}-01`})

        // Create map entries & reset page
        await store.createNewMundialSnCostMap({mapData, removeData, resetDate:`${resetDate.split('-')[0]}-${resetDate.split('-')[1]}-01`});
        this.resetFilters()
      },
      handleEdit (index, row) {
        const tmpRow = this.queriedData.find(costmap => costmap.id === row.id && costmap.date === row.date),
        ogRow = this.ogSegmentInsightsList.find(costmap => costmap.id === row.id && costmap.date === row.date);

        tmpRow.cpm_cost = ogRow.cpm_cost
        tmpRow.rs_cost = ogRow.rs_cost
        tmpRow.rs_direct_cost = ogRow.rs_direct_cost
        tmpRow.mg = ogRow.mg
        tmpRow.mg_daily_imp = ogRow.mg_daily_imp
        tmpRow.mg_monthly_imp = ogRow.mg_monthly_imp
        tmpRow.mc = ogRow.mc
        tmpRow.mc_daily_adreq = ogRow.mc_daily_adreq
        tmpRow.mc_monthly_adreq = ogRow.mc_monthly_adreq

        console.log({index, row, tmpRow, ogRow})
      },
      async handleDelete (index, row) {
        console.log({index, row})
        if (confirm(`Are you sure you want to ignore ${row.adunit}? This can be undone in Ad Units > Manage Ignored.`)) {
          const boolCheck = await store.createMundialIgnored({adunit: row.adunit, cdate: (new Date()).toISOString().split('T')[0]})

          if (boolCheck === 'EXISTING_IGNORED') {
              alert('AD UNIT already exists!')
          } else if (!boolCheck) {
              alert('AD UNIT is incorrect!')
          }
        }
      },
    },
    computed: {
      tableColumns () {
        return segmentInsightsCols
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      /***
       * Searches through table data and returns a paginated array.
       * Note that this should not be used for table with a lot of data as it might be slow!
       * Do the search and the pagination on the server and display the data retrieved from server instead.
       * @returns {computed.pagedData}
       */
      queriedData () {
        if (!this.searchQuery) {
          this.pagination.total = this.tableData.length
          return this.pagedData
        }
        const propsToSearch = segmentInsightsSearch,
        lowerSearchQuery = this.searchQuery.toLowerCase()
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of propsToSearch) {
              let rowValue = row[key].toString().toLowerCase()
              if (rowValue.includes && rowValue.includes(lowerSearchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      generateSummary () {
        const metricArr = ['sn_id','device_type_id','product_subtype_id','inter'],
        metricSet = new Set(metricArr);
        //let mundialData = JSON.parse(JSON.stringify(store.mmData.segment_insights_list ? store.mmData.segment_insights_list : []));
        let mundialData = JSON.parse(JSON.stringify(this.segmentInsightsList ? this.segmentInsightsList : [])), tableRows = [];

        // Sanitize & Standardize
        mundialData = mundialData.length ? store.sanitizeMetrics(mundialData, metricSet, false) : []
        tableRows = mundialData.length ? mundialData : []

        // SAMPLE DATA
        //store.exportRows = [{segment_id:'Attractions', demo_id:'Hispanic', traffic_perc:'100%', ctr:'10%', vcr:'90%', viewability:'30%'}]

        // Sync to export
        store.exportRows = tableRows
        return tableRows
      },
      tableData () {
        // Toggle Aggregation
        let cleanRows = [];
        if (store.mmData) {
          cleanRows = this.generateSummary
        }
        
        //console.log({cleanRows})
        
        return cleanRows;
      },
    },
    data () {
      return {
        store,
        MUNDIAL_PLATFORM,
        pagination: {
          perPage: 250,
          currentPage: 1,
          perPageOptions: [25, 50, 100, 200],
          total: 0
        },
        searchQuery: '',
        mapDict: {},
      }
    },
    mounted () {
      // Generate naming dictionary
      const tableColumns = segmentInsightsCols;
      for (let c = 0; c < tableColumns.length; c++) {
        const tmpCol = tableColumns[c];
        store.colsDict[tmpCol.prop] = tmpCol.label
      }

      // Log to console
      console.log({store})

      // Reset Dashboard
      this.resetFilters()
    },
  }
</script>
<style>
  .mmWarning {
    color: #ef8157;
  }
  #exportMM {
    color: black;
  }
  .search-mm {
    margin-top: 25px;
  }
  .body-mm {
    padding-top: 0px !important;
  }
  .mundial-vcenter > .btn-mundial-reset {
    
  }
</style>
<style lang="scss">
  .el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
  }
</style>
