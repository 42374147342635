export const preIrisCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true,
            align: 'left',
          },
          {
            prop: 'map_state',
            label: 'Map State',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'eau_name',
            label: 'Ext. Ad Unit',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'demand_partner',
            label: 'Demand Partner',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'vertical',
            label: 'Vertical',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'lang',
            label: 'Language',
            minWidth: 130,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'adreq_raw',
            label: 'Raw Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'adreq_pb_ivt',
            label: 'Pre-Bid Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          {
            prop: 'imp_raw',
            label: 'Raw Imps',
            minWidth: 150,
            align: 'right',
          },
          {
            prop: 'imp_pb_ivt',
            label: 'Pre-Bid Imps',
            minWidth: 150,
            align: 'right',
          },
          //{
            //prop: 'revenue',
            //label: 'Revenue',
            //minWidth: 140,
            //align: 'right',
          //},
          {
            prop: 'inter',
            label: 'Inter',
            minWidth: 70
          },
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const preIrisSearch = ['date', 'billing_entity', 'site_name', 'adunit_name', 'vertical', 'lang', 'device_type', 'product_type', 'product_subtype'];

export const mundialCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true,
            align: 'left',
          },
          {
            prop: 'billing_entity',
            label: 'Billing Entity',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'vertical',
            label: 'Vertical',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'lang',
            label: 'Language',
            minWidth: 130,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'mon_adreq',
            label: 'Monetizable Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          //{
            //prop: 'revenue',
            //label: 'Revenue',
            //minWidth: 140,
            //align: 'right',
          //},
          {
            prop: 'inter',
            label: 'Inter',
            minWidth: 70
          },
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const adminCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true,
            align: 'left',
          },
          {
            prop: 'billing_entity',
            label: 'Billing Entity',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'vertical',
            label: 'Vertical',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'lang',
            label: 'Language',
            minWidth: 130,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'mon_adreq',
            label: 'Monetizable Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          //{
            //prop: 'revenue',
            //label: 'Revenue',
            //minWidth: 140,
            //align: 'right',
          //},
          {
            prop: 'inter',
            label: 'Inter',
            minWidth: 90,
            align: 'right',
          },
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const adminSearch = ['date', 'billing_entity', 'site_name', 'adunit_name', 'vertical', 'lang', 'device_type', 'product_type', 'product_subtype'];

export const clientCols = [
          {
            prop: 'date',
            label: 'Date',
            minWidth: 110,
            //sortable: true
            align: 'left',
          },
          {
            prop: 'site_name',
            label: 'Site',
            minWidth: 200,
            align: 'left',
          },
          {
            prop: 'adunit_name',
            label: 'Ad Unit',
            minWidth: 380,
            align: 'left',
          },
          {
            prop: 'device_type',
            label: 'Device',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_type',
            label: 'Product',
            minWidth: 120,
            align: 'left',
          },
          {
            prop: 'product_subtype',
            label: 'Sub-Product',
            minWidth: 160,
            align: 'left',
          },
          {
            prop: 'adreq',
            label: 'Requests',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'mon_adreq',
            label: 'Monetizable Requests',
            minWidth: 240,
            align: 'right',
          },
          {
            prop: 'imp',
            label: 'Impressions',
            minWidth: 150,
            align: 'right',
          },
          //{
            //prop: 'revenue',
            //label: 'Revenue',
            //minWidth: 140,
            //align: 'right',
          //},
          {
            prop: 'ivt_rate',
            label: 'IVT Rate',
            minWidth: 120,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 140,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 80,
            align: 'right',
          },
          {
            prop: 'fill_rate',
            label: 'Fill Rate',
            minWidth: 120,
            align: 'right',
          },
        ];

export const clientSearch = ['date', 'site_name', 'adunit_name', 'device_type', 'product_type', 'product_subtype'];

// Iris Users
export const irisUserCols = [
          {
            prop: 'be_name',
            label: 'Company',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'email',
            label: 'E-Mail',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'first_name',
            label: 'First Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_name',
            label: 'Last Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'is_active',
            label: 'Status',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_login',
            label: 'Last Login',
            minWidth: 110,
            align: 'right',
          },
        ];

export const irisUserSearch = ['be_name','email','first_name','last_name'];

// Billing Entities
export const beCols = [
          {
            prop: 'be_name',
            label: 'Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'o_and_o',
            label: 'Mundial Owned',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'am_name_id',
            label: 'AM Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'lead_name_id',
            label: 'Lead Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'active',
            label: 'Status',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'pdate',
            label: 'Published',
            minWidth: 110,
            align: 'left',
          },
        ];

export const beSearch = ['be_name','o_and_o','am_name_id','lead_name_id','active','pdate'];

// Site Names
export const snCols = [
          {
            prop: 'site',
            label: 'Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'be_id',
            label: 'Billing Entity',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'vertical_id',
            label: 'Vertical',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'lang_id',
            label: 'Language',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'country_id',
            label: 'Country',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'status_level',
            label: 'Status',
            minWidth: 110,
            align: 'left',
          },
        ];

export const snSearch = ['site','be_id','vertical_id','lang_id','country_id','status_level'];

// SN Cost Map
export const snCostMapCols = [
          {
            prop: 'date',
            label: 'Report Date',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'sn_id',
            label: 'Site',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'device_type_id',
            label: 'Platform',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'product_subtype_id',
            label: 'Sub-Product',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'inter',
            label: 'Inter',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'cpm_cost',
            label: 'CPM',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'rs_cost',
            label: 'Indirect Share',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'rs_direct_cost',
            label: 'Direct Share',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mg',
            label: 'MG',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mg_daily_imp',
            label: 'MG Daily Cap',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mg_monthly_imp',
            label: 'MG Monthly Cap',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mc',
            label: 'MC',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mc_daily_adreq',
            label: 'MC Daily Cap',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mc_monthly_adreq',
            label: 'MC Monthly Cap',
            minWidth: 110,
            align: 'right',
          },
        ];

export const snCostMapSearch = ['date','sn_id','device_type_id','product_subtype_id','inter'];

// Segment Insights
export const segmentInsightsCols = [
          {
            prop: 'segment_id',
            label: 'Segments',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'placement_id',
            label: 'Placements',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'demo_id',
            label: 'Audience',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'traffic_perc',
            label: 'Traffic %',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'ctr',
            label: 'CTR',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'vcr',
            label: 'VCR',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'viewability',
            label: 'Viewability',
            minWidth: 110,
            align: 'right',
          },
        ];

export const segmentInsightsSearch = ['segment_id','demo_id',];

// External Cost Map
export const costMapCols = [
          {
            prop: 'date',
            label: 'Report Date',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'adunit',
            label: 'Ext. Ad Unit',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'cpm_cost',
            label: 'CPM',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'rs_cost',
            label: 'Indirect Share',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'rs_direct_cost',
            label: 'Direct Share',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mg',
            label: 'MG',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mc',
            label: 'MC',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mc_daily_adreq',
            label: 'Daily Cap',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'mc_monthly_adreq',
            label: 'Monthly Cap',
            minWidth: 110,
            align: 'right',
          },
        ];

export const costMapSearch = ['date','adunit','cpm_cost','rs_cost','rs_direct_cost','mg','mc','mc_daily_adreq','mc_monthly_adreq'];

// Unmapped Ad Units
export const unmappedCols = [
          {
            prop: 'date',
            label: 'Report Date',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'platform_id',
            label: 'Platform',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'adunit',
            label: 'Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'inter',
            label: 'Inter',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'counter',
            label: 'Counter',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'sn_id',
            label: 'Site',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'device_type_id',
            label: 'Device Type',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'product_subtype_id',
            label: 'Sub-Product',
            minWidth: 110,
            align: 'right',
          },
          {
            prop: 'adsize_id',
            label: 'Ad Size',
            minWidth: 110,
            align: 'right',
          },
        ];

export const unmappedSearch = ['date','platform_id','adunit','inter','counter','sn_id','device_type_id','product_type_id','adsize_id'];

// Clean Site Name
export const cleanSiteCols = [
          {
            prop: 'cname',
            label: 'Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'sn_id',
            label: 'Site',
            minWidth: 110,
            align: 'left',
          },
        ];

export const cleanSiteSearch = ['cname','sn_id'];

// Ignored Ad Units
export const ignoredCols = [
          {
            prop: 'adunit',
            label: 'Name',
            minWidth: 110,
            align: 'left',
          },
        ];

export const ignoredSearch = ['adunit'];

// Manage Users
export const manageUserCols = [
          {
            prop: 'be_name',
            label: 'Company',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'email',
            label: 'E-Mail',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'first_name',
            label: 'First Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_name',
            label: 'Last Name',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'is_active',
            label: 'Status',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'last_login',
            label: 'Last Login',
            minWidth: 110,
            align: 'right',
          },
        ];

export const manageUserSearch = ['be_name','email','first_name','last_name'];

// Site Segment Map
export const siteSegmentCols = [
          {
            prop: 'site_id',
            label: 'Site',
            minWidth: 110,
            align: 'left',
          },
          {
            prop: 'segment_id',
            label: 'Segment(s)',
            minWidth: 110,
            align: 'left',
          },
        ];

export const siteSegmentSearch = ['site_id','segment_id',];

// Campaigns
export const campaignCols = [
          {
            prop: 'campaign_name',
            label: 'Name',
            minWidth: 110,
            align: 'left',
          },
        ];

export const campaignSearch = ['campaign_name','common_name',];

