<template>
  <div>
    <ValidationObserver v-slot="{ invalid }" ref="observer" tag="form" @submit.prevent="getSegmentInsights()">
    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group">
          <ValidationProvider
              name="date_range"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Date Range"
              name="date_range"
              autocomplete="off"
              v-model="store.mmDateRange"
              :error="failed ? 'Date Range field is required': null"
              :hasSuccess="passed"
            >
              <el-date-picker v-model="store.mmDateRange" type="daterange"
                          class="daterange-mm"
                          :start-placeholder="startDate"
                          :end-placeholder="endDate"
                          :default-value="startDate"
                          :picker-options="pickerOptions1">
               </el-date-picker>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="reach"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Granularity" v-if="store.mmData"
                name="reach"
                autocomplete="off"
                v-model="store.reachSelected"
                :error="failed ? 'Reach Size is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.reachSelected"
                       placeholder="Required *">
                  <el-option v-for="option in [{value:1,label:'Broad'}]"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="demographic"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Audience" v-if="store.mmData"
                name="demographic"
                autocomplete="off"
                v-model="store.demoSelected"
                :error="failed ? 'Demographic field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default" multiple
                       size="large"
                       v-model="store.demoSelected"
                       placeholder="Required *">
                  <el-option v-for="option in store.mmData.demo_options"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="campaign"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Campaign" v-if="store.mmData"
                name="campaign"
                autocomplete="off"
                v-model="store.campaignSelected"
                :error="failed ? 'Campaign is required' : null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default"
                       size="large"
                       v-model="store.campaignSelected"
                       placeholder="Required *">
                  <el-option v-for="option in []"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3 mundial-vcenter">
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="placements"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Placements" v-if="store.mmData"
                name="placements"
                autocomplete="off"
                v-model="store.placementSelected"
                :error="failed ? 'Placements field is required': null"
                :hasSuccess="passed"
            >
              <el-select class="select-default mundial-default" multiple
                       size="large"
                       v-model="store.placementSelected"
                       placeholder="Required *">
                  <el-option v-for="option in [{value:'all',label:'All'}]"
                           class="select-default"
                           :value="option.value"
                           :label="option.label"
                           :key="option.label">
                  </el-option>
              </el-select>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-1 mundial-vcenter">
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-vcenter">
        <button @click.prevent="resetFilters()" class="btn btn-default btn-fill btn-wd btn-mundial-reset" v-if="false">Reset</button>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialBorders mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-3">
        <div class="form-group">
          <ValidationProvider
              name="keywords"
              rules="required"
              v-slot="{ passed, failed }"
          >
            <fg-input label="Segment List" v-if="store.mmData"
                name="keywords"
                autocomplete="off"
                v-model="store.csvFile"
                :error="failed ? 'Segment keywords are required': null"
                :hasSuccess="passed"
            >
              <input type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="handleFileUpload" v-model="store.csvFile"/>
            </fg-input>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-3 mundial-vcenter">
      </div>

      <div v-if="!store.mmData.user.display_tc" class="col-md-6">
        <div class="form-group" v-if="store.csvFile.length">
            <fg-input label="Preview" v-if="store.mmData"
                name="keywords"
                autocomplete="off"
                v-model="store.keywordsInput"
                :disabled="true"
            >
              <textarea class="form-control" placeholder="Please input segment keywords" rows="3" v-model="store.keywordsInput" disabled></textarea>
            </fg-input>
        </div>
      </div>
    </div>

    <div v-if="store.mmData" class="row mundialHeader">
      <div v-if="!store.mmData.user.display_tc" class="col-md-10 mundial-vcenter">
      </div>
      <div v-if="!store.mmData.user.display_tc" class="col-md-2 mundial-costmap-vcenter">
        <button @click.prevent="getSegmentInsights()" class="btn btn-success btn-fill btn-wd btn-mundial-success" :disabled="invalid">Retrieve</button>
      </div>
    </div>
    </ValidationObserver>

    <!-- segment insights table -->
    <segment-insights-tables :renderFlag="renderFlag" :segmentInsightsList="segmentInsightsList" :ogSegmentInsightsList="ogSegmentInsightsList" :resetFilters="resetFilters"></segment-insights-tables>
  </div>
</template>
<script>
  import { DatePicker } from 'element-ui'
  import { store } from 'src/store'
  import { Button, Modal } from 'src/components/UIComponents';
  import SegmentInsightsTables from 'src/components/Dashboard/Views/Tables/SegmentInsightsTables.vue'
  import { extend } from "vee-validate";
  import { required, email, min, confirmed } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("min", min);
  extend("confirmed", confirmed);  

  // Filter variables
  const mmDate = store.mmDate(),
  maxDate = store.mmDate(),
  maxDateThresh = store.mmDate(),
  minDateThresh = new Date(store.mmData.active_month),
  storeNameDict = {
    'date': 'mmDateRange',
    'product_type': 'productSelected',
    'product_subtype': 'subProductSelected',
    'billing_entity': 'companySelected',
    'site_name': 'siteSelected',
  },
  lastSeven = mmDate.GetLastSeven(),
  sDate = lastSeven['startDay'].toISOString().split('T')[0],
  eDate = lastSeven['endDay'].toISOString().split('T')[0];

  // Default daterange thresholds
  //maxDateThresh.setTime(maxDateThresh.getTime() - 3600 * 1000 * 24);
  maxDateThresh.setTime(maxDateThresh.getTime() + 3600 * 1000 * 24 * 45);

  console.log({minDateThresh,maxDateThresh})

  export default {
    components: {
      SegmentInsightsTables,
      Modal,
      [Button.name]: Button,
      [DatePicker.name]: DatePicker,
    },
    data () {
      return {
        segmentInsightsList: [],
        ogSegmentInsightsList: [],
        store,
        renderFlag: false,
        pickerOptions: {
          disabledDate (date) {
            let boolCheck = false;
            if (date > maxDateThresh) boolCheck = true
            if (date < minDateThresh) boolCheck = true
            
            //return date > maxDateThresh || date < minDateThresh
            return boolCheck
          }
        },
        pickerOptions1: {
          disabledDate (date) {
            if (store.mmData && store.mmData.user.is_staff) {
              return date > maxDateThresh
            } else {
              let boolCheck = false;
              if (date > maxDateThresh) boolCheck = true
              if (date < minDateThresh) boolCheck = true

              return boolCheck
            }
          },
          shortcuts: [{
            text: 'MTD',
            onClick (picker) {
              const monthToDate = mmDate.GetMonthToDate(),
              sDate = monthToDate['startDay'],
              eDate = monthToDate['endDay'] > maxDate ? maxDate : monthToDate['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'MTD'
            }
          },
          {
            text: 'Last 7 Days',
            onClick (picker) {
              const lastSeven = mmDate.GetLastSeven(),
              sDate = lastSeven['startDay'],
              eDate = lastSeven['endDay'] > maxDate ? maxDate : lastSeven['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last 7 Days'
            }
          },
          {
            text: 'Last 30 Days',
            onClick (picker) {
              const lastThirty = mmDate.GetLastThirty(),
              sDate = lastThirty['startDay'],
              eDate = lastThirty['endDay'] > maxDate ? maxDate : lastThirty['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last 30 Days'
            }
          },
          {
            text: 'Last Week',
            onClick (picker) {
              const lastWeek = mmDate.GetLastWeek(),
              sDate = lastWeek['startDay'],
              eDate = lastWeek['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last Week'
            }
          },
          {
            text: 'Last Month',
            onClick (picker) {
              const lastMonth = mmDate.GetLastMonth(),
              sDate = lastMonth['startDay'],
              eDate = lastMonth['endDay'];
              picker.$emit('pick', [sDate.toISOString().split('T')[0], eDate.toISOString().split('T')[0]])

              //console.log({sDate, eDate, maxDate})

              // Update Footer text
              store.mmText = 'Last Month'
            }
          }]
        },
      }
    },
    computed: {
      startDate () {
        return store.mmDateRange.length ? store.mmDateRange[0] : ''
      },
      endDate () {
        return store.mmDateRange.length ? store.mmDateRange[1] : ''
      },
      siteOptions () {
        const companySelected = store.companySelected === 'all' ? false : store.mmData.be_options.filter(obj => obj.value === store.companySelected)[0],
        be_id = companySelected ? companySelected.value : false,
        be_sn_set = be_id ? new Set(store.mmData.be_sn_dict.filter(obj => obj.be_id === be_id)[0].sn_id.split(',')) : false;

        console.log({companySelected, be_id, be_sn_set})

        //if (be_sn_set) {
          //store.siteSelected = 'all'
          //this.setSummaryData()
        //}

        return be_sn_set ? store.mmData.site_options.filter(obj => be_sn_set.has(obj.value.toString())) : store.mmData.site_options
      },
      subProductOptions () {
        const productSelected = store.productSelected === 'all' ? false : store.mmData.product_options.filter(obj => obj.value === store.productSelected)[0],
        product_id = productSelected ? productSelected.value : false,
        subproduct_list = store.mmData.product_subproduct_dict.filter(obj => obj.product_type_id === product_id),
        product_subproduct_set = product_id && subproduct_list.length ? new Set(subproduct_list[0].product_subtype_id.split(',')) : false;

        //if (product_subproduct_set) {
          //store.subProductSelected = 'all'
          //this.setSummaryData()
        //}

        console.log({productSelected, product_id, subproduct_list, product_subproduct_set})

        return product_subproduct_set ? store.mmData.subproduct_options.filter(obj => product_subproduct_set.has(obj.value.toString())) : product_id && !subproduct_list.length ? [] : store.mmData.subproduct_options
      },
    },
    methods: {
      resetFilters () {
        // Update global
        store.mmDateRange = [sDate, eDate]
        store.reachSelected = 1
        store.demoSelected = ''
        store.campaignSelected = ''
        store.placementSelected = ['all']
        store.keywordsInput = ''
        store.csvFile = ''
        store.mmData.segment_insights_list = false
        store.mmCustomCSVHeaders = false
        store.mmText = 'Last 7 Days'

        // Update local
        this.renderFlag = false;
      },
      handleFileUpload(event) {
        store.mmUploadCSV(event)
      },
      handleMoney(row, prop) {
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? new Intl.NumberFormat("en-US",{maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD'}).format(tmpVal) : '';

        //console.log({newVal})

        return newVal == 'NaN' ? '' : newVal
      },      
      handleNumbers(row, prop) {
        const tmpVal = row[prop],
        newVal = !isNaN(parseInt(tmpVal)) ? Number(tmpVal).toLocaleString("en-US") : '';

        //console.log({newVal})

        return newVal == 'NaN' ? '' : newVal
      },
      stringifyRows (dataRows) {
        const cleanRows = [], tableRows = JSON.parse(JSON.stringify(dataRows)), 
        tableLen = tableRows.length,
        handleMoney = this.handleMoney, handleNumbers = this.handleNumbers;

        for (let r = 0; r < tableLen; r++) {
          const tmpRow = tableRows[r];

          // Convert to strings
          tmpRow.cpm_cost = handleMoney(tmpRow, 'cpm_cost')
          tmpRow.mg_daily_imp = handleNumbers(tmpRow, 'mg_daily_imp')
          tmpRow.mg_monthly_imp = handleNumbers(tmpRow, 'mg_monthly_imp')
          tmpRow.mc_daily_adreq = handleNumbers(tmpRow, 'mc_daily_adreq')
          tmpRow.mc_monthly_adreq = handleNumbers(tmpRow, 'mc_monthly_adreq')

          cleanRows.push(tmpRow)
        }

        return cleanRows
      },
      generateRandomArray(length, targetSum) { // SAMPLE DATA
        const randomArray = Array.from({ length }, () => Math.random()); // Generate random numbers
        const sum = randomArray.reduce((acc, num) => acc + num, 0);

        // Scale and floor values
        const scaledArray = randomArray.map(num => Math.floor(num / sum * targetSum));

        // Adjust the last element to fix rounding errors
        scaledArray[length - 1] += targetSum - scaledArray.reduce((acc, num) => acc + num, 0);

        console.log({scaledArray})

        return scaledArray;
      },
      async getSegmentInsights () {
        this.renderFlag = false;

        // Segment Insights variables
        const dateRange = [store.mmDateRange[0], store.mmDateRange[1]],
        reachSelected = store.reachSelected,
        demoSelected = store.demoSelected,
        campaignSelected = store.campaignSelected,
        placementSelected = store.placementSelected,
        keywordsInput = store.keywordsInput,
        tmpMin = new Date(dateRange[0]),
        tmpMax = dateRange[1] ? new Date(dateRange[1]) : false,
        adjMin = new Date(tmpMin.getTime()).toISOString().split('T')[0],
        adjMax = tmpMax ? new Date(tmpMax.getTime()).toISOString().split('T')[0] : false,
        queryMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0],
        queryMax = tmpMax ? new Date(tmpMax.getTime() + 3600 * 1000 * 24 * 1).toISOString().split('T')[0] : false,
        queryDateRange = queryMax ? [queryMin, queryMax] : [queryMin],
        fileReader = new FileReader();

        // Adjust dates
        dateRange[0] = adjMin;
        dateRange[1] = tmpMax ? adjMax : adjMin;
        if (adjMax) dateRange[1] = adjMax;

        console.log({dateRange, reachSelected, demoSelected, campaignSelected, placementSelected, keywordsInput, queryDateRange});

        //
        // SAMPLE DATA
        //
        const boolCheck = keywordsInput.length ? keywordsInput.split(',') : false,
        boolLen = boolCheck ? boolCheck.length : boolCheck;
        if (boolCheck) {
          const demoArr = Array.isArray(demoSelected) ? demoSelected : [demoSelected],
          demoLen = demoArr.length, randomIntsArr = this.generateRandomArray(boolLen * demoLen, 100),
          mathFloor = Math.floor, mathRandom = Math.random;
          let sampleArr = [],
          masterIdx = 0;

          for (let i = 0; i < boolLen; i++) {
            const tmpKW = boolCheck[i];

            for (let j = 0; j < demoLen; j++) {
              const tmpDemo = demoArr[j],
              tmpPerc = randomIntsArr[masterIdx];

              sampleArr.push(
                {
                  segment_id:tmpKW,
                  placement_id:'',
                  demo_id:store.idDemoDict[tmpDemo],
                  traffic_perc:`${tmpPerc}%`,
                  ctr:`${mathFloor(mathRandom() * 10) + 1}%`,
                  vcr:`${mathFloor(mathRandom() * (60 - 45 + 1)) + 45}%`,
                  viewability:`${100 - (mathFloor(mathRandom() * 30) + 1)}%`
                }
              )
              masterIdx++
            }
          }

          this.segmentInsightsList = sampleArr
          this.ogSegmentInsightsList = JSON.parse(JSON.stringify(this.segmentInsightsList))
        }
        //
        // END SAMPLE
        //

        // Retrieve DB data
        //const boolCheck = await store.getMundialSegmentInsights({dateRange, reachSelected, demoSelected, campaignSelected, placementSelected, keywordsInput, queryDateRange});
        //if (boolCheck) {
          //this.segmentInsightsList = await this.stringifyRows(store.mmData.segment_insights_list)
          //this.ogSegmentInsightsList = JSON.parse(JSON.stringify(this.segmentInsightsList))
        //}

        // Update render flag
        this.renderFlag = true;

        // Populate custom headers
        //store.mmCustomCSVHeaders = {dateRange, reachSelected:store.idReachDict[reachSelected], demoSelected:store.idDemoDict[demoSelected], keywordsInput}
        store.mmCustomCSVHeaders = {}
        store.mmCustomCSVHeaders['Date Range'] = dateRange
        store.mmCustomCSVHeaders['Granularity'] = store.idReachDict[reachSelected]
        store.mmCustomCSVHeaders['Audience'] = Array.isArray(demoSelected) ? demoSelected.map(x => store.idDemoDict[x]) : store.idDemoDict[demoSelected]
        store.mmCustomCSVHeaders['Segments'] = keywordsInput
        store.mmCustomCSVHeaders['Campaign'] = campaignSelected
        store.mmCustomCSVHeaders['Placements'] = Array.isArray(placementSelected) ? placementSelected.map(x => store.idPlacementDict[x]) : store.idPlacementDict[placementSelected]
      },
      async getCostMaps () {
        this.renderFlag = false;

        //console.log({store})

        // Mapping variables
        const dateRange = [store.mmDateRange[0], store.mmDateRange[1]],
        billingEntity = store.companySelected,
        siteName = store.siteSelected,
        deviceType = store.deviceSelected,
        productType = store.productSelected,
        productSubtype = store.subProductSelected,
        inter = store.interSelected,
        tmpMin = new Date(dateRange[0]),
        tmpMax = dateRange[1] ? new Date(dateRange[1]) : false,
        adjMin = new Date(tmpMin.getTime()).toISOString().split('T')[0],
        adjMax = tmpMax ? new Date(tmpMax.getTime()).toISOString().split('T')[0] : false,
        //adjMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0],
        //adjMax = tmpMax ? new Date(tmpMax.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0] : false,
        //queryMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 2).toISOString().split('T')[0],
        queryMin = new Date(tmpMin.getTime() - 3600 * 1000 * 24 * 1).toISOString().split('T')[0],
        queryMax = tmpMax ? new Date(tmpMax.getTime() + 3600 * 1000 * 24 * 1).toISOString().split('T')[0] : false,
        queryDateRange = queryMax ? [queryMin, queryMax] : [queryMin];

        // Adjust dates
        //dateRange[0] = tmpMin;
        //dateRange[1] = tmpMax ? tmpMax : tmpMin;
        dateRange[0] = adjMin;
        dateRange[1] = tmpMax ? adjMax : adjMin;
        if (adjMax) dateRange[1] = adjMax;

        //console.log({store,sDate:store.mmDateRange[0].toISOString().split('T')[0],eDate:store.mmDateRange[1].toISOString().split('T')[0]})
        //console.log({dateRange, productType, productSubtype, billingEntity, siteName, queryDateRange})

        // Retrieve DB data & exclude unmapped entries (sncostmap_list)
        //const snCostMaps = [{id:678},{id:679}],
        const snCostMaps = store.mmData.sncostmap_list,
        boolCheck = await store.getMundialSnCostMap({dateRange, productType, productSubtype, billingEntity, siteName, queryDateRange, inter, deviceType, snCostMaps});
        if (boolCheck) {
          this.segmentInsightsList = await this.stringifyRows(store.mmData.segment_insights_list)
          this.ogSegmentInsightsList = JSON.parse(JSON.stringify(this.segmentInsightsList))
        }

        // Update render flag
        this.renderFlag = true;
      }
    },
    mounted () {
      if (store.mmData.sessionFilters) store.mmData.sessionFilters = false

      //
      // Set Dashboard Defaults
      //
      store.mmDateRange = [sDate, eDate]
      store.reachSelected = 1
      store.demoSelected = ''
      store.campaignSelected = ''
      store.placementSelected = ['all']
      store.keywordsInput = ''
      store.csvFile = ''
      store.mmText = 'Last 7 Days'
      //this.resetFilters()
    },
    beforeUnmount () {
      //this.resetFilters()
    },
  }
</script>
<style>
  .mm-create {
    overflow-y: visible !important;
  }
  .date-mundial {
    border-radius: 12px !important;
    border: 1px solid #ddd !important;
  }
  .date-mundial > .el-input__inner {
    border-radius: 10px !important;
  }
  .mundial-costmap-vcenter > .btn-mundial-success {
    float: right;
  }
  .form-group input[type=file] {
    opacity: 100;
    position: relative;
  }
  input#file-upload-button {
    padding-right: 10rem !important;
  }
</style>
